/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faBracketsCurly, faCog } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col } from "reactstrap";
import { switchTenant } from "../../../actions/Auth/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import { CONFIG_TYPES } from "../../../actions/Tenants/config/constants";
import ED from "../../../img/logo-ed.png";
import useAvosApi from "../../../utils/useAvosApiHook";
import DebugModal from "../../Helper/DebugModal";
import FooterEyePlatformStripes from "./FooterEyePlatformStripes";

export default function Footer() {
    const user = useSelector((state) => state.auth.user);
    const location = useLocation();
    const testing = user.is_admin && user.tenant?.tenant_id !== "a6aff297-ac1b-4e81-88a6-420591d31f31";

    const testingBlockStyle = css`
        ${testing ? "bottom:3.8rem;" : "bottom:0;"}
    `;
    const footerBlockHeight = css`
        ${testing ? "height: 15rem;" : "height: 12.5rem;"}
    `;

    const LayerIndex = location.pathname.startsWith("/layer/tab");


    return (

        <div className="">
            <div css={footerBlockHeight} className="footer text-center text-muted py-3 d-flex align-items-center">
                {(LayerIndex) && <FooterEyePlatformStripes top={testing ? "0" : "3.8rem"}/>}
                {!LayerIndex && <div css={testingBlockStyle} className="footer-copyright d-flex">
                    <Col lg="2">
                    </Col>
                    <Col xs={8} md={8}>
                        <small>
                            Powered by
                            <img src={ED} alt="Logo Experience Data" className="ms-2 d-inline-block" />
                            <a className="text-dark ms-1 text-decoration-none" target="_blank" href="https://www.experiencedata.nl/" rel="noreferrer">Experience Data</a>
                        </small>
                    </Col>
                    <Col xs={4} lg="2">
                        <VersionTag />
                    </Col>
                </div>}
            </div>
            {testing && <TestBanner />}
        </div>
    );
}

function VersionTag() {
    const user = useSelector((state) => state.auth.user);

    const frontend_version = process.env?.REACT_APP_VERSION;
    const timer = useRef();
    const isED = user.tenant?.tenant_id === "a6aff297-ac1b-4e81-88a6-420591d31f31";
    const [getBackendVersion, backendVersion] = useAvosApi("/health/version");
    const backend_version = backendVersion?.version;
    const version_not_equal = frontend_version && backend_version && frontend_version !== backend_version;


    useEffect(() => {
        // * Check backend version every 10 seconds, but do not spam with toast if temporarily unavailable/no interwebs
        getBackendVersion(null, { no_toast: true });
        timer.current = setInterval(() => getBackendVersion(null, { no_toast: true }), 10000);
        return () => clearInterval(timer.current);
    }, []);


    if (!frontend_version) {
        return <small>Version <b>dev</b></small>;
    }

    if (isED) {
        return <small title={`frontend: ${frontend_version} - backend: ${backend_version}`}>
            Version <b><a href={`https://github.com/experiencedata/avos_server/commit/${frontend_version}`} target="_blank" rel="noreferrer">{frontend_version.slice(0, 7)}</a></b>
            {version_not_equal && <span> / <a className="fw-bold" href={`https://github.com/experiencedata/avos_server/commit/${backend_version}`} target="_blank" rel="noreferrer">{backend_version.slice(0, 7)}</a></span>}
        </small>;
    }

    return <small title={`frontend: ${frontend_version} / backend: ${backend_version}`}>
        Version <b>{frontend_version.slice(0, 7)}</b>{version_not_equal && <span className="fw-bold">-{backend_version.slice(0, 7)}</span>}
    </small>;
}


function TestBanner() {
    const params = useParams();
    const config = useConfig();
    const user = useSelector((state) => state.auth.user);
    const layer = useSelector((state) => state.layers.current);
    const check = useSelector((state) => state.checks.current);
    const [debugModalState, setDebugModal] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tenant_id = user.tenant?.tenant_id;
    const tenant_name = user.tenant?.tenant_name;

    const restoreTenantAndNavigate = () => {
        navigate(`/tenants`);
        dispatch(switchTenant({ tenant_id: "a6aff297-ac1b-4e81-88a6-420591d31f31" }));
    };

    const urls = [
        {
            url: `/tenants/${tenant_id}`,
            text: `Edit ${tenant_name} config`
        }
    ];
    if (params.layer_id && layer?.type) {
        const layer_config = config.configs.find((c) => c.value === layer.type && c.type === "layer_type");
        if (layer_config) {
            urls.push({
                url: `/tenants/${tenant_id}/config/${layer_config.id}`,
                text: `${layer_config.config.text} config`,
                debug: {
                    props: {
                        data: layer,
                    },
                    title: `Debug ${layer_config.config.text} ${layer.label}`
                }
            });
        }
    }
    if (params.test_id && check?.location) {
        const check_config = config.configs.find((c) => c.value === check.location && c.type === "check_location");
        if (check_config) {
            urls.push({
                url: `/tenants/${tenant_id}/config/${check_config.id}`,
                text: `${check_config.config.text} config`,
                debug: {
                    props: {
                        data: check,
                    },
                    title: `Debug check ${check.test_id}`
                }
            });
        }
    }
    if (params.active_tab) {
        const tab = config.configs.find((c) => c.value === params.active_tab && c.type === "stage");
        if (tab) {
            urls.push({
                url: `/tenants/${tenant_id}/config/${tab.id}`,
                text: `Tab ${tab.config.text} config`
            });
            urls.push({
                url: `/tenants/${tenant_id}/tab/${CONFIG_TYPES.STAGE}`,
                text: `Edit tabs`
            });
        }
    }


    return (
        <>
            {urls
                .filter((i) => i.debug)
                .map((i) => <DebugModal
                    key={i.debug.title}
                    docProps={i.debug.props}
                    title={i.debug.title}
                    isOpen={debugModalState[i.debug.title]}
                    className="mx-1"
                    close={() => setDebugModal((prev) => ({ ...prev, [i.debug.title]: false }))} />)}
            <Alert color="danger" className="mb-0">
                <div className="py-3">
                    <div className="text-center font-weight-bold pb-2 ">You are testing the frontend as <b>{tenant_name}</b>.</div>
                    <div className="text-center">
                        {urls.map((i, index) => <Button
                            key={index}
                            color="secondary"
                            size="sm"
                            className="mx-1"
                            onClick={() => navigate(i.url)} ><FontAwesomeIcon icon={faCog} /> {i.text}</Button>)}
                        {urls
                            .filter((i) => i.debug)
                            .map((i) => <Button
                                key={i.debug.title}
                                color={"secondary"}
                                size="sm"
                                className="mx-1"
                                onClick={() => setDebugModal((prev) => ({ ...prev, [i.debug.title]: true }))}
                            ><FontAwesomeIcon icon={faBracketsCurly} /> {i.debug.title}</Button>)}
                        <Button color="secondary" size="sm" className="mx-1" onClick={() => restoreTenantAndNavigate()} >Back to tenants</Button></div>
                </div>
            </Alert>
        </>
    );
}

