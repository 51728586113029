/** @jsxImportSource @emotion/react */
import useConfig from "../../../../actions/Tenants/config/configHook";
import { layerFormFields } from "../../../../actions/Tenants/config/layerFormFields";
import FilterableConfigList from "./FilterableConfigList";
import { yerOrNoRadio } from "./util";

const defaultField = {
    field: {
        name: "meta_key",
        prefix_location: false,
    },
    // display_filter: {
    //     fruit_types: [],
    //     exclude_fruit_types: [],
    //     layer_types: [],
    // // exclude_layer_types: [],
    // }
};
// function to get all the meta field names for check level from the check forms
export function getConfiguredCheckMetaFieldNames() {
    const config = useConfig();
    return (config.check_locations || []).flatMap((i) => [
        "meta_form_summary",
        "pre_check_form",
        "visual_check_form",
        "advance_ripening_start_form",
        "meta_form_advance_ripening",
    ].flatMap((x) => (i[x] || []).flatMap((y) => y.field?.name)));
}

// function to get all the fruit field names for check level from the check forms
export function getConfiguredFruitFieldNames() {
    const config = useConfig();

    const all_fruit_fields = (config.check_locations || []).flatMap((i) => [
        "fruit_form",
    ].flatMap((x) => (i[x] || []).flatMap((y) => y.field?.name)));
    // remove duplicates
    return Object.keys(all_fruit_fields.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
    }, {
        atron_pressures_median: true,
    }));
}

// function to get all the layer field names for check level from the check forms
export function getConfiguredLayerFieldNames(include_common_fields_packing_list = false) {
    const config = useConfig();
    const layer_fields_from_check = (config.check_locations || []).flatMap((i) => [
        "meta_form_layer",
    ].flatMap((x) => (i[x] || []).flatMap((y) => y.field?.name)));
    const layer = (config.layer_types || []).flatMap((i) => [
        "meta_form",
    ].flatMap((x) => (i[x] || []).flatMap((y) => y.field?.name)));
    return [...layer_fields_from_check, ...layer, ...(include_common_fields_packing_list ? layerFormFields.map((i) => i.name) : [])];
}

export default function CopyMetaConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => {
        return <span>{object.name}</span>;
    };

    const checkNames = getConfiguredCheckMetaFieldNames();

    const getFieldForm = (object) => [
        {
            label: "name",
            description: "Select name from one of the check forms.",
            type: "single-select",
            name: "name",
            options: checkNames.map((i) => ({ label: i, value: i })),
        },
        {
            label: "Custom name",
            name: "name",
            type: "text",
            description: "Custom name for this field, use this if you check meta is not set via one of the forms. Ask a developer when in doubt.",
        },
        {
            label: "Prefix location",
            name: "prefix_location",
            description: `Prefix the location to the meta key. When true will save both '${object.name}' and '{location}_${object.name}' in layer meta.`,
            ...yerOrNoRadio
        }

        // You should actually never update if blank
        // {
        //     label: "Only copy if filled",
        //     description: "Only copy this field if it is filled.",
        //     name: "mandatory",
        //     advanced: false,
        //     for_types: false,
        //     ...yerOrNoRadio
        // },

    ];

    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
        useDisplayFilter={false}
    />;

}


