/** @jsxImportSource @emotion/react */
import { faBarcodeRead } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { createCheck } from "../../../../actions/Checks/actions";
import { CHECK_LOCATION, Check, Layer } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault, isFilledArray } from "../../../../utils";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";


import { toast } from "../../../../utils/toast";
import QRCodeScan from "../../../Forms/QRCodeScan";
import useRipeWise, { isItemAllocatedOnDateHook } from "../hooks/useRipeWise";

export interface StartCheckButtonProps {
    group_pallets: [Layer];
    pallet: Layer;
    group: false | {group_label: string, group_query: string}
    disabled: boolean;
}

const getRipeningCheckConfig = (pallet, group_pallets, group) => {
    const config = useConfig();

    const ripening_check_config = config.get_location({}, { location: "ripening" });

    const layerIsUpdating = useSelector<any>((state) => state.layers.isUpdating);
    const checkIsUpdating = useSelector<any>((state) => state.checks.isUpdating);
    const params = useParams();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [modalOpen, setModal] = useState(false);
    const [createCheckData, setCheck] = useState<{ check_on_pallet_or_group?: string }>({});
    const { location_id } = params;

    const group_question = {
        label: "Check applies on pallet or group?",
        type: "radio",
        name: "check_on_pallet_or_group",
        options: [{
            label: "Pallet",
            value: "pallet"
        }, {
            label: "Group",
            value: "group"
        }],
        required: true
    };

    const today = dayjs();
    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();
    const group_pallets_still_in_cell = getFilledArrayOrDefault(group_pallets).filter((p) => isItemAllocatedOnDate(p.id, today));
    const form = group && group_pallets_still_in_cell.length > 1 ? [group_question, ...getFilledArrayOrDefault(ripening_check_config?.pre_check_form)] : getFilledArrayOrDefault(ripening_check_config?.pre_check_form);


    const shouldShowModal = isFilledArray(form);

    const updateLayerAndStartCheck = useCallback(() => {
        if (!pallet) {
            return;
        }

        if (layerIsUpdating || checkIsUpdating) {
            return;
        }

        if (form && !metaFormIsValid(form as any, createCheckData)) {
            return;
        }

        const test: Check = {
            layer_id: group && createCheckData?.check_on_pallet_or_group === "group" ? group_pallets_still_in_cell.map((i) => i.id) : [pallet.id],
            location_id,
            location: CHECK_LOCATION.RIPENING,

        } as any;

        const postCheck = {
            ...test, // * Coded check properties
            ...createCheckData, // * Form fields
            ...(group && createCheckData?.check_on_pallet_or_group === "group" ? group : {}) // * Group data if available
        };
        dispatch(createCheck(postCheck) as any).then((response) => {
            if (!response.error) {
                const { flow } = ripening_check_config;

                if (flow.length === 0) {
                    setModal(false);
                    return;
                }

                navigate(`/layer/${pallet.id}/add-check/${response.payload.data.test_id}/${flow[0]}`);
            }
        });
    }, [form]);

    const isDisabled = () => {
        if (layerIsUpdating || checkIsUpdating) {
            return true;
        }

        return false;
    };
    const startCheck = () => {
        // only show modal if extra meta is required on this location
        if (shouldShowModal) {
            setModal(true);
            return;
        }
        updateLayerAndStartCheck();
    };

    const submit = () => {
        updateLayerAndStartCheck();
    };

    return { form, modalOpen, setModal, createCheckData, setCheck, isDisabled, startCheck, submit };

};


export default function StartCheckButton({ pallet, group_pallets, group, disabled }: StartCheckButtonProps) {
    const { form, modalOpen, setModal, createCheckData, setCheck, isDisabled, startCheck, submit } = getRipeningCheckConfig(pallet, group_pallets, group);
    const config = useConfig();
    return (
        <div>
            <div className="text-end">
                <Button
                    className="my-1 text-nowrap"
                    disabled={disabled || isDisabled()}
                    color="primary"
                    size="sm"
                    onClick={startCheck}>Check</Button>
            </div>
            <Modal isOpen={modalOpen} size={"md"} toggle={() => setModal(false)}>
                <ModalHeader toggle={() => setModal(false)} >Start check</ModalHeader>
                <ModalBody>
                    <div>
                        {isFilledArray(form) && <MetaForm
                            key="check"
                            meta={form}
                            setValue={(field, value) => setCheck((object) => ({ ...object, [field]: value }))}
                            object={createCheckData}
                            config={config} />}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => setModal(false)}>Cancel </Button>
                    <Button disabled={isDisabled()} color="success" onClick={() => submit()}>Start</Button>
                </ModalFooter>
            </Modal >
        </div>);
}


export function ScanToCheckModal() {
    const { pallets, groups } = useRipeWise();
    const config = useConfig();
    const [query, setQuery] = useState<any>({});

    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();
    const today = dayjs();
    const activePallets = pallets.filter((p) => isItemAllocatedOnDate(p.id, today));


    const handleScan = (result: string) => {
        const filteredResult = config.barcode_filter(result);
        /**
         * Scan the barcode and search for pallet with that label
         */
        const layer = activePallets.find((p) => p.label === filteredResult);

        if (!layer) {
            toast.info("Pallet not found");
            return;
        }

        setQuery((prev) => ({ ...prev, layer_id: layer.id }));

    };


    const findPalletform = [
        {
            type: "element",
            el: <QRCodeScan onScan={handleScan} />
        },
        {
            type: "radio",
            label: "Pallet",
            name: "layer_id",
            options: activePallets.map((p) => ({ label: p.label, value: p.id }))
        },

    ];
    // Find pallet in todays pallet
    const pallet = activePallets.find((p) => p.id === query.layer_id);
    // Find pallet in group pallets
    const group_pallets = groups.find((pallets) => pallets.some((i) => i.id === query.layer_id));

    // console.log("pallets", pallets);
    // console.log("activePallets", activePallets);
    // console.log("query", query);
    // console.log("pallet", pallet);
    // console.log("group_pallets", group_pallets);
    // console.log("group", pallet?.group || false);

    const { form, modalOpen, setModal, createCheckData, setCheck, isDisabled, submit } = getRipeningCheckConfig(pallet || {}, group_pallets, pallet?.group || false);


    return <>
        <Modal isOpen={modalOpen} size="md">
            <ModalHeader >Scan to check pallets</ModalHeader>
            <ModalBody>
                <MetaForm
                    meta={findPalletform}
                    object={query}
                    config={config}
                    setValue={(field, value) => setQuery((prev) => ({ ...prev, [field]: value }))}
                />
                <div>
                    {isFilledArray(form) && <MetaForm
                        key="check"
                        meta={form}
                        setValue={(field, value) => setCheck((object) => ({ ...object, [field]: value }))}
                        object={createCheckData}
                        config={config} />}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={!query.new_location_id || !query.layer_id || activePallets.length === 0 || isDisabled()} onClick={() => submit() }>Start check</Button>
                <Button color="light" onClick={() => setModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
        <Button className="my-1 text-nowrap" disabled={activePallets.length === 0 || isDisabled()} color="primary" onClick={() => setModal(true)}><FontAwesomeIcon icon={faBarcodeRead} /> Scan to check</Button>
    </>;

}
