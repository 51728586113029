/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { applyDisplayFilterFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { find_category } from "../../../../actions/Tenants/config/utils";
import { getFilledArrayOrDefault, isFilledArray } from "../../../../utils";
import { toPercentage } from "../../Layers/fields/formatters";
import useRipeWise, { countAllocatedLayersHook, formatDate, isItemAllocatedOnDateHook } from "../hooks/useRipeWise";


export const MetricCardComponents = {
    number_of_purchase_orders: MetricCardNumberOfPurchaseOrders,
    number_of_ggn: MetricCardUniqueGGN,
    number_of_sizes: MetricCardUniqueSize,
    number_of_fruit_varieties: MetricCardFruitVariety,
    age_average: MetricCardAgeAverage,
    occupancy_latest: MetricCardOccupancyLatest,
};

export interface MetricCardProps {
    title: string,
    children: any,
    color?: string | null
    description?: any

}

interface CustomMetricCardProps {
    metric: {
        flags: object[],
        label: string,
        default_flag: string
    },
    pallets: any[],
    lastWeekDay: dayjs.Dayjs
}

interface MetricCardWithoutPalletsProps {
    metric: {
        flags: object[],
        label: string,
        default_flag: string
    },
    lastWeekDay: dayjs.Dayjs
}

function getFlagColor(value, flags, default_flag) {
    const flag = find_category(value, flags);
    if (flag) {
        return flag.flag;
    }
    return default_flag;

}

export default function RipeWiseMetricCardsGroup() {
    const config = useConfig();
    const { pallets, lastWeekDay } = useRipeWise();
    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();
    const activePallets = pallets.filter((p) => isItemAllocatedOnDate(p.id, lastWeekDay));


    const metrics = applyDisplayFilterFields(
        config?.root_config?.ripewise_metric_cards,
        {
            // fruit_type: "avocado"  # TODO: discuss fruit_type fillters
        }
    );

    if (!isFilledArray(metrics)) {
        return null;
    }

    return (
        <div className="pb-5">
            <div className="d-flex align-items-start pt-3 flex-nowrap">
                {getFilledArrayOrDefault(metrics, []).map((i, index) => {
                    const MetricComponent = MetricCardComponents[i.key];
                    if (!MetricComponent) {
                        // eslint-disable-next-line no-console
                        console.log(`MetricCardComponent not found for key: ${i.key}`);
                        return null;
                    }
                    return <MetricComponent key={index} metric={i} pallets={activePallets} lastWeekDay={lastWeekDay} />;
                })}
            </div>
        </div>
    );
}


export function MetricCard({ title, children, color, description }: MetricCardProps) {
    const [modal, setModal] = useState(false);
    return (
        <>
            <div onClick={() => setModal(true)} css={css`border-radius:8px; width: 20%; `} className={`p-3 me-3 overflow-hidden  position-relative bg-white card-alert --flag-${color}`} >
                {description && <span css={css`position: absolute; top: 2%; right: 4%; opacity: 0.4;`}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </span>}
                <div className="d-flex align-items-center flex-wrap py-1 display-4 text-nowrap text-truncate">
                    {children}
                </div>
                <div className="text-secondary font-weight-bold text-truncate"><b>{title}</b></div>
            </div>
            {description && <Modal isOpen={modal} toggle={() => setModal(false)}>
                <ModalHeader toggle={() => setModal(false)}>Description</ModalHeader>
                <ModalBody>{description}</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            }
        </>
    );
}

function MetricCardUniqueGGN({ metric, pallets, lastWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Global Gap Numbers on <b>{formatDate(lastWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.global_gap_number).map((i) => i.global_gap_number));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}

function MetricCardUniqueSize({ metric, pallets, lastWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Sizes on <b>{formatDate(lastWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.box_size).map((i) => i.box_size));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}

function MetricCardFruitVariety({ metric, pallets, lastWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Fruit Varieties on <b>{formatDate(lastWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.fruit_variety).map((i) => i.fruit_variety));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}

function MetricCardAgeAverage({ metric, pallets, lastWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the age on <b>{formatDate(lastWeekDay)}</b>.</span>;

    const harvestDates = (pallets || [])
        .filter((i) => i.harvest_date)
        .map((i) => new Date(i.harvest_date).getTime());

    if (harvestDates.length === 0) {
        return <MetricCard title={metric.label} description={description}>0</MetricCard>;
    }

    const min_harvest_date = new Date(Math.min(...harvestDates));
    const max_harvest_date = new Date(Math.max(...harvestDates));

    const max_age = dayjs().diff(min_harvest_date, "day");
    const min_age = dayjs().diff(max_harvest_date, "day");

    return (<MetricCard title={metric.label} description={description}>{min_age} - {max_age}</MetricCard>);
}

function MetricCardOccupancyLatest({ metric, lastWeekDay }: MetricCardWithoutPalletsProps) {
    const description = <span>This metric shows the occupancy on <b>{formatDate(lastWeekDay)}</b>.</span>;

    const countALlocatedLayers = countAllocatedLayersHook();
    const loaded = countALlocatedLayers(lastWeekDay);

    return (<MetricCard title={metric.label} description={description}>{toPercentage(loaded, 20)} </MetricCard>);
}


function MetricCardNumberOfPurchaseOrders({ metric, pallets, lastWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique PO on <b>{formatDate(lastWeekDay)}</b>.</span>;

    const unique = new Set((pallets || []).flatMap((i) => i.parents.map((p) => p.id)));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}

