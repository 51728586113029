/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    FormGroup, Input,
    Table
} from "reactstrap";
import { applyDisplayFilterLayerFields, setupFieldClassFromConfig } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig, { useTenantFormOptions } from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";
import { TableCell, TableHeaderCell } from "../../../Helper/Table";
import useRipeWise, { isItemAllocatedOnDateHook, LIST_OR_GRID } from "../hooks/useRipeWise";
import { RemoveLayerModal } from "./MovePalletsModal";
import StartCheckButton from "./StartCheckButton";

// Table to show either the pallets or the groups within a PO
export default function RipeningCellItems() {

    const config = useConfig();
    const checks = useSelector<any, any>((state) => state.facilityLocations.checks);
    const form_options = useTenantFormOptions();
    const { groups, pallets, weekDates, refreshAllocatedLayers, listOrGrid, selectedLayers, cellRows, cellColumns, setSelectedLayers, selectedLayersArray, groupAccentColors, fruit_type, fruit_type_config } = useRipeWise();

    const [addPalletModalOpen, setAddPalletModal] = useState(false);
    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();

    const fields = applyDisplayFilterLayerFields(
        config?.root_config?.ripening_cell_pallet_fields,
        {
            fruit_type
        }
    );


    const today = dayjs();

    if (!pallets || !fields) {
        return <Loading />;
    }

    const values = (p) => fields.map((i, index) => <TableCell key={index}>{i.set_value({
        config,
        layer: p,
        check: null, // * To be used in check tables and layer overview
        fruit: null, // * Fruit list is not available on overview screen
        children: null, // * children is only available on layer index page
        form_options
    }).display_component()}</TableCell>);

    // const WrappedInColorAccentBadge = (value, field, pallet) => {
    //     // * Only give box size, ggn, variety etc an accent color
    //     if ([GROUP_STRATEGY.PALLET, GROUP_STRATEGY.PURCHASE_ORDER].includes(pallet.group?.group_strategy)) {
    //         return value;
    //     }
    //     // * ony give properties an highlight that are being used for grouping
    //     if (field.fieldname_getter !== pallet.group?.group_strategy) {
    //         return value;
    //     }
    //     return <Badge css={[groupAccentColors[pallet.group?.group_query]]} pill>
    //         {value}
    //     </Badge>;

    // };


    const header = () => fields.map((i, index) => <TableHeaderCell key={index}>{i.label}</TableHeaderCell>);


    const toggleSelectedLayer = (layer_id) => setSelectedLayers((prev) => ({ ...prev, [layer_id]: !prev[layer_id] }));

    // * Select all pallets (the one that are still in the ripening cell, on this date)
    const selectAll = useCallback(() => {
        setSelectedLayers(pallets.reduce((acc: object, i: any) => ({ ...acc, [i.id]: isItemAllocatedOnDate(i.id, today) }), {}) as object);
    }, [pallets]);
    const deselectAll = () => setSelectedLayers({});

    // Check if all pallets have a selected layer and are allocated for today, ensuring the list is not empty
    const allSelected = pallets.length > 0 && pallets.every((i) => selectedLayers[i.id] && isItemAllocatedOnDate(i.id, today));

    // Handler to toggle select/deselect all
    const toggleSelectAll = () => {
        if (allSelected) {
            deselectAll();
        } else {
            selectAll();
        }
    };

    // const gridPallets = pallets.filter((p) => (listOrGrid === LIST_OR_GRID.GRID && p.cell_level === filterLevel));
    const cellIndexPalletId = pallets.map((i) => i.id);

    const ripeningField = setupFieldClassFromConfig(fruit_type_config?.ripening_field);

    let cellIndex = -1; // TODO: temporary Used to allocate pallets to cell locations (arbirtary without ERP)

    return <div>
        <div>
            <Button size="sm" className="my-1 me-2 text-nowrap" disabled={selectedLayersArray.length === 0} color="primary" onClick={() => setAddPalletModal(true)}>Move {selectedLayersArray.length}</Button>
        </div>
        <div >
            <div css={css`min-height: 300px;`}>
                { listOrGrid === LIST_OR_GRID.LIST && <Table size="sm" responsive={true} borderless={true}>
                    <thead>
                        <tr>
                            <TableHeaderCell><Input checked={allSelected}
                                onChange={toggleSelectAll} type="checkbox" /></TableHeaderCell>
                            {header()}
                            {weekDates.map((date, index) => (
                                <TableHeaderCell key={index} >
                                    {date.format("D MMM YYYY")}
                                </TableHeaderCell>
                            ))}
                            <TableHeaderCell>Check</TableHeaderCell>
                        </tr>
                    </thead>
                    {groups.map((pallets: any, index) => <tbody key={index} css={css`border-width: 1px 0px 1px 0px; border-type: solid; border-color: #323030;`}>
                        {pallets.map((pallet, indexx) => <tr key={indexx} title={pallet?.group?.group_query}>
                            <TableCell>
                                <FormGroup check>
                                    <Input disabled={!isItemAllocatedOnDate(pallet.id, today)} onChange={() => toggleSelectedLayer(pallet.id)} checked={Boolean(selectedLayers[pallet.id]) && isItemAllocatedOnDate(pallet.id, today)} type="checkbox" />
                                </FormGroup>
                            </TableCell>
                            {values(pallet)}
                            {weekDates.map((date, dateIndex) => (
                                <TableCell
                                    key={dateIndex}
                                    className={isItemAllocatedOnDate(pallet.id, date) ? "align-middle text-center bg-info-subtle" : "align-middle text-center bg-body-tertiary text-muted"}
                                >{checks.filter((c) => dayjs(c.created).format("DD-MM-YYYY") === date.format("DD-MM-YYYY") && c.layer_id === pallet.id)
                                        .map((check, index) => <Link key={index} to={`/layer/${check.layer_id}/add-check/${check.test_id}/summary`}>{ripeningField.set_value({
                                            config,
                                            layer: pallet,
                                            check, // * To be used in check tables and layer overview
                                            fruit: null, // * Fruit list is not available on overview screen
                                            children: null, // * children is only available on layer index page
                                            form_options

                                        }).display_component()} </Link>)}
                                </TableCell>
                            ))}
                            <TableCell>
                                {<StartCheckButton
                                    pallet={pallet}
                                    disabled={!isItemAllocatedOnDate(pallet.id, today)}
                                    group_pallets={pallets}
                                    group={pallet.group} />}
                            </TableCell>
                        </tr>)}
                    </tbody>)}
                </Table > }
                { listOrGrid === LIST_OR_GRID.GRID && <div >
                    {cellRows.map((row, rowIndex) => <div key={rowIndex} className="d-flex flex-nowrap pb-2 mx-n3">
                        {cellColumns.map((column, columnIndex) => {
                            if (column.toLowerCase() === "hall") {
                                return <div key={columnIndex} className="px-3 mx-3" ></div>;
                            }

                            // TODO: temporary hack to allocate pallets to cell locations
                            cellIndex++; // Track pallet index globally


                            return <div key={columnIndex} className="p-3 border rounded mx-3" css={css`min-width: 33rem;`}>{column}{row}
                                {groups.flatMap((pallets: any) => pallets
                                    // .filter((p) => (listOrGrid === LIST_OR_GRID.GRID && p.cell_level === filterLevel))
                                    .filter((p) => cellIndexPalletId[cellIndex] === p.id)
                                    // .filter((p) => p.cell_row === row && p.cell_column === column) // * Filter pallets for this cell TODO: enable when ERP connection is Ready
                                    .map((p, indexx) => <div key={indexx} className="border rounded-end p-3 my-3" css={[css`border-width: 1px 1px 1px 10px!important;`, groupAccentColors[p.group?.group_query]]}>
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 fw-bold">{p.label}</h5>
                                            <div className="ms-auto">
                                                <FormGroup check>
                                                    <Input disabled={!isItemAllocatedOnDate(p.id, today)} onChange={() => toggleSelectedLayer(p.id)} checked={Boolean(selectedLayers[p.id]) && isItemAllocatedOnDate(p.id, today)} type="checkbox" />
                                                </FormGroup>
                                            </div>
                                            <div >
                                                {<StartCheckButton
                                                    pallet={p}
                                                    disabled={!isItemAllocatedOnDate(p.id, today)}
                                                    group_pallets={pallets}
                                                    group={p.group} />}
                                            </div>

                                        </div>
                                        <table>
                                            {[...fields, ripeningField].filter((i) => i.fieldname_getter !== "label").map((i, index) => <tr key={index}>
                                                <td>{i.label}</td>
                                                <td className="fw-bold ps-2">
                                                    {
                                                        i.set_value({
                                                            config,
                                                            layer: p,
                                                            check: null, // * To be used in check tables and layer overview
                                                            fruit: null, // * Fruit list is not available on overview screen
                                                            children: null, // * children is only available on layer index page
                                                            form_options
                                                        }).display_component()
                                                    }

                                                </td>
                                            </tr>)}

                                        </table>

                                    </div>))}
                            </div>;
                        })}
                    </div>)}


                </div>
                }
            </div>
        </div>
        <RemoveLayerModal
            setModal={setAddPalletModal}
            modalOpen={addPalletModalOpen}
            selectedLayers={selectedLayersArray}
            refreshAllocatedLayers={refreshAllocatedLayers}
            deselectAll={deselectAll}
        />
    </div>;

}
