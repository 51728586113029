import { Text, View } from "@react-pdf/renderer";
import { TenantDefect } from "../../../../Forms/useManagedOptionsHook";
import { toPercentage } from "../../fields/formatters";
import { styles } from "./PDFCheckFruitTables";
import { truncateText } from "./utils";

interface Defect {
    defect_id: string;
    frequency: number;
}
interface Avocado {
    defects: Defect[];
}
interface Check {
    defect_list: Defect[];
    avocados: Avocado[];
    sample_size: number;
}
interface DefectGroup {
    defect_position: string[];
    total_defects_title: string;
    decimals?: number;
    denominator?: string;
}

interface PDFContainerDefectsProps {
    defect_group: DefectGroup;
    all_defects: TenantDefect[];
    printChecks: Check[];
}

const PDFContainerDefects: React.FC<PDFContainerDefectsProps> = ({ defect_group, all_defects, printChecks }) => {
    if (!all_defects) return null;

    // Calculate total defects and related values
    const calculateBasedOnDefectGroup = (checks, defect_group) => {
        const decimals = defect_group.decimals || 1;

        // calculate total defects
        const getFruitDefectSummary = (checks) => {
            const fruit_defects = checks.reduce((acc, check) => {
                const defects = [
                    ...check.defect_list,
                    ...check.avocados.flatMap((avocado) => avocado.defects)
                ];

                defects.forEach(({ defect_id, frequency }) => {
                    acc[defect_id] = (acc[defect_id] || 0) + frequency;
                });

                return acc;
            }, {});

            return Object.keys(fruit_defects).map((defect_id) => {
                const defect = all_defects.find((d) => d.defect_id === defect_id);
                const { position, label } = defect || {};
                return {
                    defect_id,
                    value: fruit_defects[defect_id],
                    position: position || null,
                    label: label || null
                };
            });
        };

        const calculateFruitsHasDefects = (checks) => checks.reduce((acc, check) => {
            if (!check.avocados) return acc;
            const fruits_with_defects = check.avocados.filter((avocado) => avocado.defects?.length > 0).length;
            return acc + fruits_with_defects;
        }, 0);

        const getSum = (checks, key) => checks.reduce((acc, check) => acc + Number(key(check)), 0);

        // Pre-compute values
        const fruit_defect_summary = getFruitDefectSummary(checks);
        const sum_fruits_has_defects = calculateFruitsHasDefects(checks);
        const sample_size_sum = getSum(checks, (check) => check.sample_size);
        const avos_fruit_sum = getSum(checks, (check) => check.avocados.length);

        // Total defects based on position
        const sum_of_all_defects = fruit_defect_summary
            .filter((defect) => defect.position === defect_group.defect_position[0])
            .reduce((acc, defect) => acc + defect.value, 0);

        // Choose denominator and defects sum
        const denominator = defect_group.denominator === "avos_fruit" ? avos_fruit_sum : sample_size_sum;
        const total_defects = defect_group.denominator === "avos_fruit" ? sum_fruits_has_defects : sum_of_all_defects;

        return {
            fruit_defect_summary,
            denominator: denominator || 1,
            total_defects,
            decimals
        };
    };

    // Calculate values based on defect_group
    const { fruit_defect_summary, denominator, total_defects, decimals } = calculateBasedOnDefectGroup(printChecks, defect_group);

    const defect_percentage = (value) => toPercentage(value, denominator, decimals);

    return (
        <View style={[styles.metaContainer, styles.horizontalMargins]}>
            <View>
                <View style={styles.metaItems}>
                    <View>
                        <Text style={{ fontSize: "9px" }}>{defect_group.total_defects_title}: </Text>
                    </View>
                    <View style={styles.metaTextValueIntakeReport}>
                        <Text>{defect_percentage(total_defects)}</Text>
                    </View>
                </View>
                <View style={styles.divider}></View>
                {fruit_defect_summary
                    .filter((defect) => defect.position === defect_group.defect_position[0])
                    .map((defect, index) => (
                        <View key={index} style={styles.metaItems}>
                            <View>
                                <Text style={{ fontSize: "9px" }}>{truncateText(defect.label, 28, 26)}:</Text>
                            </View>
                            <View>
                                <Text>{defect_percentage(defect.value)}</Text>
                            </View>
                        </View>
                    ))}
            </View>
        </View>
    );
};


export default PDFContainerDefects;
