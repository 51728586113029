/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { addLocationCondition } from "../../../../actions/FacilityLocations/actions";
import { applyDisplayFilterFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../../utils";
import MetaForm from "../../../Forms/MetaForm";
import useRipeWise, { formatDate, getDayConditions } from "../hooks/useRipeWise";


// RipeningCell component
export default function RipeningCellConditionsForm() {
    const { refreshConditions, editDate, closeCellConditionsForm } = useRipeWise();
    const params = useParams();

    const dispatch = useDispatch();
    const config = useConfig();
    const [query, setQuery] = useState({});

    const { location_id } = params;

    const formFields = applyDisplayFilterFields(config?.root_config?.cell_conditions_form, {}).map((i) => ({ ...i, disabled: !editDate?.isValid() }));


    const addCondition = (field, value) => {
        dispatch(addLocationCondition({
            location_id,
            key: field,
            value,
            active_since: editDate.format("YYYY-MM-DDTHH:mm:ss"),
            created: dayjs().toISOString(),
        })).then(() => {
            // Refresh conditions for given timeframe
            refreshConditions();
        });
    };

    const { keyLabelMap } = getDayConditions(editDate);
    useEffect(() => {
        keyLabelMap.map((item) => setQuery((prev) => ({ ...prev, [item.key]: item.value })));
    }, [getFilledArrayOrDefault(keyLabelMap).map((i) => i.value).join(",")]);

    return <Modal isOpen={editDate?.isValid()} toggle={() => closeCellConditionsForm()}>
        <ModalHeader toggle={() => closeCellConditionsForm()}>Add conditions</ModalHeader>
        <ModalBody>
            <Alert color="info">You are changing the cell conditions for <b>{formatDate(editDate)}</b></Alert>
            <MetaForm
                config={config}
                object={query}
                grid={false}
                meta={formFields}
                setValue={(field, value) => setQuery((prev) => ({ ...prev, [field]: value }))}
                onDebounce={(field, value) => addCondition(field, value)}
                debounce_time={200}
            />
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={() => closeCellConditionsForm()}>Save</Button>
        </ModalFooter>
    </Modal>;
}
