import { useSelector } from "react-redux";
import useConfig from "../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../utils";
import {
    useFruitTypes,
    useUserPreferences,
} from "../../../utils/useUserPreferences";
import { CheckboxButtons } from "../../Forms/CheckboxButtons";

export default function FruitFilter() {
    const config = useConfig();
    // const filter = useSelector<any, any>((state) => state.layers.filter);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, updatePreferences] = useUserPreferences();
    const allOptions = useSelector(
        (state: any) => state?.tenants?.options_by_field
    );
    const fruitTypeOptions = allOptions?.[config.tenant_id]?.fruit_type || [];
    const selectedFruitTypes = useFruitTypes();

    const onFruitTypeChange = (fruit_type: (string | number | boolean)[]) => updatePreferences({ fruit_types: getFilledArrayOrDefault(fruit_type as string[]) });

    return (
        <div>
            <CheckboxButtons
                color="secondary"
                return_array={true}
                options={fruitTypeOptions.map((option) => ({
                    ...option,
                    className: "mx-1",
                }))}
                selectedOptions={selectedFruitTypes}
                onChange={onFruitTypeChange}
            />
        </div>
    );
}
