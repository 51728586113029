/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faGrid, faList } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Container, Table
} from "reactstrap";
import { listFacilityLocations } from "../../../actions/FacilityLocations/actions";
import { applyDisplayFilterLayerFields } from "../../../actions/Tenants/config/applyDisplayFilter";
import useConfig, { useTenantFormOptions } from "../../../actions/Tenants/config/configHook";
import { TableCell, TableHeaderCell } from "../../Helper/Table";
import { LOCATION_TYPES } from "../Tenants/TenantEditFacilityLocationsForm";
import RipeWiseBreadcrumbs from "./RipeWiseBreadcrumbs";
import StyledBox from "./StyledBox";
import { LIST_OR_GRID } from "./hooks/useRipeWise";

const RipeningCellsTableWrapped = () => {

    return (
        <Container className="pt-3">
            <h2>Ripecells</h2>
            <RipeWiseBreadcrumbs currentLocation={null} />
            <StyledBox >
                <RipeningCellsTable />
            </StyledBox>
        </Container>
    );
};

export default RipeningCellsTableWrapped;


// DataTable component for displaying cells
export function RipeningCellsTable() {

    const dispatch = useDispatch();
    const filter = useSelector((state: any) => state.facilityLocations.filter);
    const ripening_cells = useSelector((state: any) => state.facilityLocations.locations);
    const form_options = useTenantFormOptions();
    const [listOrGrid, setListOrGrid] = useState(LIST_OR_GRID.LIST);

    useEffect(() => {
        dispatch(listFacilityLocations({ type: LOCATION_TYPES.RIPENING_CELL, ...filter }));
    }, []);

    const config = useConfig();
    const navigate = useNavigate();

    const fields = applyDisplayFilterLayerFields(config?.root_config?.ripening_cell_overview_fields, {
        // fruit_type: "avocado"  # TODO: discuss fruit_type fillters
    });

    if (fields.length === 0) {
        return null;
    }
    const values = (c) => fields.map((i, index) => <TableCell key={index}>{i.set_value({
        config,
        ripeningCell: c,
        form_options
    }).display_component()}</TableCell>);

    const renderHeader = () => {
        return fields.map((field, index) => (
            <TableHeaderCell key={index} >{field.label}</TableHeaderCell>
        ));
    };

    // * commented out some divs to place future filters
    return (

        <div>
            <div className="d-flex justify-content-between">
                <div>
                    <div className="d-flex">
                        {/* <GroupStrategyModal /> */}
                        <div className="me-1">
                            <Button color="light" onClick={() => setListOrGrid((prev:string) => (prev === LIST_OR_GRID.LIST ? LIST_OR_GRID.GRID : LIST_OR_GRID.LIST))}>
                                {listOrGrid === LIST_OR_GRID.LIST && <span><FontAwesomeIcon icon={faGrid}></FontAwesomeIcon> Grid</span>}
                                {listOrGrid === LIST_OR_GRID.GRID && <span><FontAwesomeIcon icon={faList}></FontAwesomeIcon> List</span>}
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    {/* <ScanToMoveLayerModal /> */}
                    {/* <ScanToCheckModal/> */}
                </div>
            </div>
            <div className="pt-4 d-flex flex-column" css={css`min-height: 50vh;`} >
                <div >
                    { listOrGrid === LIST_OR_GRID.LIST && <Table size="sm" responsive={true} borderless={true}>
                        <thead>
                            <tr>{renderHeader()}</tr>
                        </thead>
                        <tbody>
                            {ripening_cells.map((i, index) => <tr key={index} className="clickable" title={i.location_id} onClick={() => navigate(`/ripening-cell/${i.location_id}`)}>{values(i)}</tr>)}
                        </tbody>
                    </Table >}
                    { listOrGrid === LIST_OR_GRID.GRID && <div className="d-flex flex-nowrap pb-2 mx-n3">
                        {ripening_cells.map((c, indexx) => <div key={indexx} className="border rounded p-3 my-3 border-primary" >
                            <table>
                                {fields.map((i, index) => <tr key={index}>
                                    <td>{i.label}</td>
                                    <td className="fw-bold ps-2">{i.set_value({
                                        config,
                                        ripeningCell: c,
                                        form_options
                                    }).display_component()}
                                    </td>
                                </tr>)}
                            </table>
                        </div>)}
                    </div>}
                </div>
            </div>
        </div>);
}
