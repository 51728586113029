import { faGrid, faList } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
    Button, Container
} from "reactstrap";
import { Loading } from "../../../Helper/Loading";
import useRipeWise, { LIST_OR_GRID, RipeWiseProvider } from "../hooks/useRipeWise";
import RipeWiseBreadcrumbs from "../RipeWiseBreadcrumbs";
import { GroupStrategyModal } from "./GroupStrategyModal";
import RipeWiseMetricCardsGroup from "./MetricCards";
import { AddSinglePalletModal, ScanToMoveLayerModal } from "./MovePalletsModal";
import RipeningCellConditionsForm from "./RipeningCellConditionsForm";
import RipeningCellDays from "./RipeningCellDays";
import RipeningCellItems from "./RipeningCellItems";
import { ScanToCheckModal } from "./StartCheckButton";

export default function RipeningCellLayoutWrapped() {
    return <RipeWiseProvider>
        <RipeningCellLayout />
    </RipeWiseProvider>;
}

function RipeningCellLayout() {

    const currentLocation = useSelector((state: any) => state.facilityLocations.current);


    const {
        listOrGrid,
        setListOrGrid,
        filterLevel,
        setFilterLevel,
        cellLevels,
        fruit_type_config
    } = useRipeWise();


    const [addPalletModalOpen, setAddPalletModal] = useState(false);


    if (!currentLocation || !fruit_type_config || !fruit_type_config.ripening_field) {
        return <Loading />;
    }


    // TODO: add color for ripewise
    return <>

        <div className="px-3 pt-3 pb-5">
            {/* Header */}
            {/* Title */}
            <Container>
                <div className="d-flex align-items-center justify-content-between flex-grow-1 overflow-auto" >
                    <div>
                        <h2 className="mb-1" >
                            {currentLocation?.label}
                        </h2>
                        <RipeWiseBreadcrumbs currentLocation={currentLocation} />
                    </div>
                    <div className="">
                        <Button
                            className="my-1 text-nowrap"
                            color="primary"
                            onClick={() => setAddPalletModal(true)}>Add pallets</Button>
                    </div>
                </div>
            </Container>
            {/* Layers & KPI */}
            <Container>
                <RipeWiseMetricCardsGroup />
            </Container>
            <RipeningCellDays />
        </div>
        {/* Ripening Days */}
        <Container >
            <div className="bg-body rounded shadow">
                <div className="p-lg-5 p-2" >
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="d-flex">
                                <GroupStrategyModal />
                                <div className="ms-1">
                                    <Button color="light" onClick={() => setListOrGrid((prev:string) => (prev === LIST_OR_GRID.LIST ? LIST_OR_GRID.GRID : LIST_OR_GRID.LIST))}>
                                        {listOrGrid === LIST_OR_GRID.LIST && <span><FontAwesomeIcon icon={faGrid}></FontAwesomeIcon> Grid</span>}
                                        {listOrGrid === LIST_OR_GRID.GRID && <span><FontAwesomeIcon icon={faList}></FontAwesomeIcon> List</span>}
                                    </Button>
                                </div>
                                { listOrGrid === LIST_OR_GRID.GRID && cellLevels.length > 0 && cellLevels.map((level, index) => <div key={index} className="ms-1">
                                    <Button color={filterLevel === level ? "primary" : "light"} onClick={() => setFilterLevel(level)}>{level}</Button>
                                </div>)}
                            </div>
                        </div>
                        <div>
                            <ScanToMoveLayerModal />
                            <ScanToCheckModal/>
                        </div>
                    </div>
                    <RipeningCellItems/>
                </div>
            </div>
        </Container>
        {/* Ripening Cell Conditions Form */}
        <RipeningCellConditionsForm />
        <AddSinglePalletModal setModal={setAddPalletModal} modalOpen={addPalletModalOpen} />
    </>;

}

