/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { useSelector } from "react-redux";
import { AVOS_GRAPH_COLORS, VIEW } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";

const Plot = createPlotlyComponent(Plotly);

function onlyUnique<T>(value: T, index: number, self: T[]): boolean {
    return self.indexOf(value) === index;
}

const isValidNumber = (number: any): boolean => (number > 0 || parseFloat(number) === 0) && number !== "" && number !== null && number !== undefined;

interface Subconfig {
    view?: string;
    filter_field?: string;
    filter_location?: string;
}

interface FirmnessGraphProps {
    title?: string;
    subconfig: Subconfig;
}

export default function FirmnessGraph({ subconfig, title = "Progress External Firmness" }: FirmnessGraphProps) {
    const checks = useSelector((state: any) => state.layers.checks);
    const config = useConfig();
    const layer = useSelector((state: any) => state.layers.current);
    const isLoading = useSelector((state: any) => state.layers.isLoading);
    const { filter_field, filter_location, view } = subconfig;

    if (isLoading || !checks) {
        return <Loading />;
    }

    const fruit_type = config.get_fruit_type(layer.fruit_type);

    const the_field = fruit_type?.ripening_field;

    if (!the_field?.fieldname_getter) {
        return null;
    }

    const filtered_checks = checks
        .filter((i: any) => (filter_field && layer.id !== i.layer_id ? i[filter_field] === layer[filter_field] : true))
        .filter((i: any) => (filter_location && layer.id !== i.layer_id ? i.location === filter_location : true));

    if (filtered_checks.length === 0) {
        return null;
    }

    const ordered = filtered_checks.sort((a: any, b: any) => a.test_id - b.test_id).filter((i: any) => isValidNumber(i[the_field.fieldname_getter]));

    if (ordered.length === 0) {
        return null;
    }

    const avos_green = AVOS_GRAPH_COLORS.AVOS_SUCCESS;
    let graphs = [];

    if (view === VIEW.CHILDREN) {
        const layer_ids = filtered_checks.map((i: any) => i.layer_id).filter(onlyUnique);
        graphs = layer_ids.map((layer_id: number) => ({
            type: "scatter",
            x: ordered.filter((i: any) => i.layer_id === layer_id).map((i: any) => dayjs(i.created).toISOString()),
            y: ordered.filter((i: any) => i.layer_id === layer_id).map((i: any) => i[the_field.fieldname_getter]),
            mode: "lines+points+markers",
            name: the_field.label,
            marker: {
                color: avos_green,
                size: 10,
            },
            line: {
                color: avos_green,
                width: 3,
            },
        }));
    } else {
        graphs = [
            {
                type: "scatter",
                x: ordered.map((i: any) => dayjs(i.created).toISOString()),
                y: ordered.map((i: any) => i[the_field.fieldname_getter]),
                mode: "lines+points+markers",
                name: the_field.label,
                marker: {
                    color: avos_green,
                    size: 10,
                },
                line: {
                    color: avos_green,
                    width: 3,
                },
            }] as any;
    }

    const range = [0, Math.ceil(Math.max(...ordered.map((i: any) => i[the_field.fieldname_getter])) * 1.2)];

    const layout = {
        autosize: true,
        showlegend: false,
        margin: {
            l: 50,
            r: 10,
            t: 0,
            b: 30,
            pad: 0
        },
        yaxis: {
            fixedrange: true,
            title: the_field.label,
            range,
            autorange: fruit_type.higher_value_is_more_ripe ? "reversed" : false
        },
        xaxis: {
            fixedrange: true
        }
    };

    return (
        <div className="w-100 pb-5">
            <div className="bg-body rounded shadow p-5">
                <div className="d-flex">
                    <div>
                        <h3 className="mb-0">{title}</h3>
                    </div>
                </div>
                <div className="pt-3" css={css`min-height: 20rem;`}>
                    <Plot
                        data={graphs}
                        useResizeHandler={true}
                        style={{ width: "100%", height: "100%" }}
                        layout={layout as any}
                        config={{
                            displayModeBar: false,
                            displaylogo: false,
                            staticPlot: true,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
