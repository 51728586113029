/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { CATEGORIES_WITH_CHECK_BUTTON, LAYER_TAB_CATEGORIES } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { headerLinks } from "../../../../actions/Tenants/config/constants";
import MetaForm from "../../../Forms/MetaForm";
import FieldConfigForm from "./FieldConfigForm";
import FormConfigForm from "./FormConfigForm";
import NavigationConfigForm from "./NavigationConfigForm";
import RipeWiseMetricCardsConfigForm from "./RipeWiseMetricCardsConfigForm";

export const defaultRootConfig = {
    text: "root",
    value: "root",
    primary_header_links: [
        { path: headerLinks.home, label: "Overview" }
    ],
    secondary_header_links: [
        { path: headerLinks.settings, label: "Settings" },
        { path: headerLinks.logout, label: "Logout" },
    ],
    overview_general_filter_form: []
};

export default function RootConfigForm({ config_data, setConfigData }) {
    const config = useConfig();
    const { tenant_id } = useConfig();

    const form = [

        {
            label: "Value",
            description: "What is the slug or identifier of this layer type. Used in the database and in urls.",
            name: "value",
            type: "single-select",
            readOnly: true,
            default: "root",
            options: [{ value: "root", label: "root" }]
        },
        {
            type: "element",
            el: <NavigationConfigForm
                name="primary_header_links"
                title="Primary header links"
                description="Primary navigation links in the header"
                fields={config_data.primary_header_links}
                setArray={(value) => setConfigData({ ...config_data, primary_header_links: value })}
            />
        },
        {
            type: "element",
            el: <NavigationConfigForm
                name="secondary_header_links"
                title="Secondary header links"
                description="Secondary navigation links in the header"
                fields={config_data.secondary_header_links}
                setArray={(value) => setConfigData({ ...config_data, secondary_header_links: value })}
            />
        },
        {
            label: "Default Fruit type for Layer Creation",
            name: "default_fruit_type",
            type: "single-select",
            options: config.fruit_types.map((i) => ({ value: i.value, label: i.text }))
        },
        {
            type: "element",
            el: <FormConfigForm
                fields={config_data.overview_general_filter_form || []}
                description="Specify the fields will generate the general layer filter in the overview screen."
                name="overview_general_filter_form"
                title="General Filter"
                setArray={(overview_general_filter_form) => setConfigData({ ...config_data, overview_general_filter_form })} />
        },
        {
            type: "header",
            label: "Tab Config"
        },
        {
            label: "Tab categories with Start Check button",
            name: "tab_categories_with_check_button",
            description: <div>What tab categores should show the big green button to start check. Will default to {CATEGORIES_WITH_CHECK_BUTTON.join(", ")}</div>,
            type: "single-select",
            multi: true,
            return_array: true,
            options: Object.keys(LAYER_TAB_CATEGORIES).map((i) => ({ value: LAYER_TAB_CATEGORIES[i], label: i }))
        },
        {
            type: "header",
            label: "RipeWise Config"
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.ripening_cell_overview_fields || []}
                description={<span>What fields to display on the ripening cell overview screen (<Link to={`/tenants/${tenant_id}/facility-locations`}>list of cells</Link>).</span>}
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="ripening_cell_overview_fields"
                title="Ripening Cell Overview"
                setArray={(ripening_cell_overview_fields) => setConfigData({ ...config_data, ripening_cell_overview_fields })} />
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.ripening_cell_pallet_fields || []}
                description="What fields to display on the ripening cell screen (list of allocated pallets)."
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="ripening_cell_pallet_fields"
                title="Ripening Cell Overview"
                setArray={(ripening_cell_pallet_fields) => setConfigData({ ...config_data, ripening_cell_pallet_fields })} />
        },
        {
            type: "element",
            el: <RipeWiseMetricCardsConfigForm
                fields={config_data.ripewise_metric_cards || []}
                description="Show metric cards on the ripening cell screen."
                name="ripewise_metric_cards"
                title="Ripening Cell Metrics"
                setArray={(ripewise_metric_cards) => setConfigData({ ...config_data, ripewise_metric_cards })} />

        },
        {
            type: "element",
            name: "cell_conditions_form",
            label: "Conditions Form",
            el: <FormConfigForm
                fields={config_data.cell_conditions_form || []}
                description="A form to manually set the conditions of the ripecell."
                name="cell_conditions_form"
                title="Conditions Form"
                setArray={(cell_conditions_form) => setConfigData({ ...config_data, cell_conditions_form })} />
        },
        {
            type: "header",
            label: "RipeWise Default Cell layout"
        },
        {
            type: "text",
            name: "cell_fysical_levels",
            label: "Cell Levels",
            description: "Give a comma seperated list of the cell levels. For example: A,B,C or -1,0,1 or 1,2,3,4,5 or Ground Floor, First Floor, Second Floor. Defaults to Level 0, Level 1, Level 2",
            placeholder: "0,1,2",
        },
        {
            type: "text",
            name: "cell_fysical_columns",
            label: "Location Columns",
            description: "Give a comma seperated list of the cell columns.  Use `hall` to define a hallway. For example: A,hall,B or 1,2,hall,3,5",
        },
        {
            type: "text",
            name: "cell_fysical_rows",
            label: "Location Rows",
            description: "Give a comma seperated list of the cell rows. Use `hall` to define a hallway. For example: A,hall,B or 1,2,hall,3,5",
        }
    ];

    return <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />;
}
