export const getOptionLabel = (tenantOptions, optionType: string, value: any): string => {
    const options = tenantOptions?.[optionType] || [];
    const option = options.find((option) => option.value === value);
    return option?.label || "";
};

export const isSupplierPortal = () => {
    const { hostname } = window.location;
    // Extract the first part of the hostname (subdomain)
    const firstPart = hostname.split(".")[0];
    // Check if the first part ends with "portal"
    return firstPart.endsWith("portal");
};
